import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertService } from './service/alert.service';
import { HomeComponent } from './home/home.component';
//import { AlertComponent } from '../ui/alert/alert.component';
//import { SpinnerComponent } from '../ui/spinner/spinner/spinner.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { Dashboard } from './dashboard/dashboard.component';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [],
  declarations: [
    HomeComponent,
    NotFoundComponent,
    SideMenuComponent,
    Dashboard
  ],
  exports: [
    HomeComponent, 
    SideMenuComponent
  ]
})
export class SharedModule { }