import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertType } from '../../ui/enum/alert-type';


export class AlertMessage {
    public type: AlertType;
    public message: string;
    public autoHide: boolean;
}

@Injectable()
export class AlertService {
    public alertStatus: BehaviorSubject<AlertMessage> = new BehaviorSubject<AlertMessage>({ type: null, message: null, autoHide: false });

    showAlert(msgType: AlertType, msg: string, isAutoHide: boolean = true) {
        let alertObj: AlertMessage = { type: msgType, message: msg, autoHide: isAutoHide };
        this.alertStatus.next(alertObj);
    }
}