import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { appRouting } from './app.routing';
import { UiModule } from './ui/ui.module';
import { AlertService } from './shared/service/alert.service';
import { NgaModule } from './theme/nga.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BaImageLoaderService } from './theme/services/baImageLoader/baImageLoader.service';
import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './global.state';
import { UserProfileService } from './shared/service/user-profile.service';
import { AboutModule } from './about/about.module';
import { JobBookingModule } from './jobBooking/jobBooking.module';
import { AccountModule } from './account/account.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConsultantService } from './Farm/services/consultant.service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

const APP_PROVIDERS = [
  AppState,
  GlobalState
];
export type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    SharedModule,
    UiModule,
    NgaModule.forRoot(),
    appRouting
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    UserProfileService,
    AlertService,
    APP_PROVIDERS,
    BaImageLoaderService,
    ConsultantService
    ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class AppModule { }
