import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { APIURL } from '../../../providers/constant';
import { Http, ResponseContentType } from '@angular/http';


@Injectable()
export class ConsultantService {

    apiUrl: string = APIURL;

    constructor(private http: Http) { };

    getAllConsultantsData(): Observable<Consultant[]> {
        let url = this.apiUrl + 'api/consultant/getAllConsultants/';

        return this.http.get(url)
            .map(resp => {
                let result = resp.json() as Consultant[];
                return result;
            });
    }

    getCustomers(consultantId: string): Observable<Customer[]> {
        let url = this.apiUrl + 'api/consultant/GetCustomersByConsultantId/' + consultantId;

        return this.http.get(url)
            .map(resp => {
                let result = resp.json() as Customer[];
                return result;
            });
    }

    public addNFarmConsultant(addNFarmConsultantCommand: AddNFarmConsultantCommand): Observable<NFarmConsultant> {

        let url = this.apiUrl + 'api/consultant/AddUpdateFarmConsultant';

        return this.http.post(url, addNFarmConsultantCommand)
            .map(resp => {
                let result = resp.json() as NFarmConsultant;
                return result;
            });
    }

    public removeFarmConsultant(farmId, consultantId): Observable<any> {
        
        const removeFarmConsultantCommand = {
            farmId: farmId,
            consultantId: consultantId
        } as RemoveFarmConsultantCommand;

        let url = this.apiUrl + 'api/consultant/removeFarmConsultant';

        return this.http.post(url, removeFarmConsultantCommand)
            .map(resp => {
                let result = resp.json() as any;
                return result;
            });
    }


}

export class RemoveFarmConsultantCommand {
    public farmId: string;
    public consultantId: string;
}

export class NFarmConsultant {
    public customerGuid: string;
    public farmGuid: string;
    public consultantGuid: string;
    public exisingConsultantGuid: string;
    public active: boolean;
}


export class AddNFarmConsultantCommand {
    public active: boolean;
}

export class Consultant {
    // public guid: string;
    // public firstName: string = null;
    // public surname: string = null;
    // public email: string = null;
    // public company: string = null;
}

export class Customer {
    public clientId: string;
    public customerName: string;
}
