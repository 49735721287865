import { Component, OnInit, ViewContainerRef } from '@angular/core';
import * as $ from 'jquery';

import { GlobalState } from './global.state';
import { BaImageLoaderService, BaThemePreloader } from './theme/services';
import { BaThemeConfig } from './theme/theme.config';
import { layoutPaths } from './theme/theme.constants';
import { Routes } from '@angular/router';
import { PAGES_MENU_FOR_CUSTOMER, PAGES_MENU_FOR_CONSULTANT } from './pages.menu';
import { BaMenuService } from './theme/services/baMenu/baMenu.service';
import { ActivatedRoute, Router,NavigationEnd } from '@angular/router'
import { User, UserProfileService } from './shared/service/user-profile.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  isMenuCollapsed: boolean = false;

  constructor(private userService : UserProfileService,
    private _state: GlobalState,
    private _imageLoader: BaImageLoaderService,
    //private _spinner: BaThemeSpinner,
    private viewContainerRef: ViewContainerRef,
    private themeConfig: BaThemeConfig,
    private _menuService: BaMenuService,
    private router: Router,
    private route: ActivatedRoute) {

    themeConfig.config();

    this._loadImages();

    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
  }

  public ngAfterViewInit(): void {
    // hide spinner once all loaders are completed
    // BaThemePreloader.load().then((values) => {
    //   this._spinner.hide();
    // });
  }

  private _loadImages(): void {
    // register some loaders
    //BaThemePreloader.registerLoader(this._imageLoader.load('/assets/img/sky-bg.jpg'));
  }

  ngOnInit() {
    // TODO: remove side menu if user is not logged in
    const user = this.userService.getUserProfile();
    if (user.isConsultant) {
      this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU_FOR_CONSULTANT);
    } else {
      this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU_FOR_CUSTOMER);
    }

    // check if user is logged in
    // if (user) {
    //   console.log('user logged in');
    // } else {
    //   console.log('user not logged in');
    //   this.router.navigate(['/account/login']);
    // }

    // // let user = new User();
    // // user.customerGuid = 'CDEFEE02-F22B-4836-8236-FEE0BF0D92CD';
    // // user.customerName = 'Jay';
    // this.userService.setUserProfile(user);
  }
}
