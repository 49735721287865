import { NgModule } from '@angular/core';
import { AlertComponent } from './alert/alert.component';
import { SelectBoxComponent } from './select-box/select-box.component';
import { TextboxComponent } from './textbox/textbox.component';
import { SpinnerComponent } from './spinner/spinner/spinner.component';
import { GoolgleMapService } from './services/google-map-service';
import { CommonModule } from '@angular/common';
import { MapNewComponent } from "./map-new/map-new.component";
import { GMapModule, InputTextModule, ButtonModule,DialogModule, CheckboxModule, AutoCompleteModule} from 'primeng/primeng';
import { FormsModule } from '@angular/forms';
import { GrowlModule } from 'primeng/primeng';
import {TableModule} from 'primeng/table';
import { InputFieldComponent } from './input-field/input-field.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    GMapModule,
    InputTextModule,
    TableModule,
    ButtonModule,
    DialogModule,
    CheckboxModule,
    GrowlModule,
    AutoCompleteModule
  ],
  providers: [
    GoolgleMapService
  ],
  declarations: [
    AlertComponent,
    SpinnerComponent,
    MapNewComponent,
    SelectBoxComponent,
    TextboxComponent,
    InputFieldComponent
  ],
  exports: [
    AlertComponent,
    SpinnerComponent ,
    MapNewComponent
  ]
})
export class UiModule { }