export const PAGES_MENU_FOR_CUSTOMER = [
  {
    //path: 'pages',
    path: '',
    children: [
      {
        path: 'home',
        data: {
          menu: {
            title: 'My Jobs',
            icon: 'fa fa-briefcase',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'myFarm',
        data: {
          menu: {
            title: 'My Farm',
            icon: 'fa fa-map',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'account',
        data: {
          menu: {
            title: 'My Account',
            icon: 'fa fa-user',
            selected: false,
            expanded: false,
            order: 100,
          }
        }
      },
      {
        path: 'myData',
        data: {
          menu: {
            title: 'My Data',
            icon: 'fa fa-database',
            selected: false,
            expanded: false,
            order: 250,
          }
        }
      },
      {
        path: 'bookNewJob',
        data: {
          menu: {
            title: 'Book New Job',
            icon: 'fa fa-plus',
            selected: false,
            expanded: false,
            order: 200,
          }
        }
      },
      {
        path: '',
        data: {
          menu: {
            title: 'Log out',
            icon: 'fa fa-sign-out',
            selected: false,
            expanded: false,
            order: 200,
          }
        }
      }
    ]
  }
];


export const PAGES_MENU_FOR_CONSULTANT = [
  {
    //path: 'pages',
    path: '',
    children: [
      {
        path: 'home',
        data: {
          menu: {
            title: 'My Jobs',
            icon: 'ion-clipboard',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'account',
        data: {
          menu: {
            title: 'My Account',
            icon: 'ion-person',
            selected: false,
            expanded: false,
            order: 100,
          }
        }
      },
      {
        path: 'myData',
        data: {
          menu: {
            title: 'My Data',
            icon: 'ion-map',
            selected: false,
            expanded: false,
            order: 250,
          }
        }
      },
      {
        path: 'bookNewJob',
        data: {
          menu: {
            title: 'Book New Job',
            icon: 'ion-ios-flask',
            selected: false,
            expanded: false,
            order: 200,
          }
        }
      },
      {
        path: '',
        data: {
          menu: {
            title: 'Log out',
            icon: 'fa fa-sign-out',
            selected: false,
            expanded: false,
            order: 200,
          }
        }
      }
    ]
  }
];
