import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './shared/not-found/not-found.component';

const appRoutes: Routes = [
  { 
    path: '', 
    loadChildren: () => import('app/Farm/farm.module').then(m => m.FarmModule)    
  },
  {
    path: 'home',
    loadChildren: () => import('app/Farm/farm.module').then(m => m.FarmModule)
  },
  {
    path: 'account',
    loadChildren: () => import('app/account/account.module').then(m => m.AccountModule)
  },
  // {
  //   path: 'myData',
  //   loadChildren: 'app/Farm/farm.module#FarmModule'
  // },
  {
    path: 'bookNewJob',
    loadChildren: () => import('app/jobBooking/jobBooking.module').then(m => m.JobBookingModule)
  },
  { 
    path: '**', 
    component: NotFoundComponent 
  }
];

export const appRouting: ModuleWithProviders = RouterModule.forRoot(appRoutes);
