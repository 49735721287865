import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APIURL } from '../../../providers/constant';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
// TODO JP: this is required to avoid this.http.get(...).map is not a function error. 
// find proper solution as farm service works without below statement. Something wrong module
import 'rxjs/Rx';
declare var localStorage : any;

@Injectable()
export class UserProfileService {
    apiUrl: string = APIURL;
    //private user: User = null;

    constructor(private http: Http,
                private router: Router){ }

    getUserByEmail(email: string, password: string): Observable<User> {
        let url = this.apiUrl + 'api/User/GetUserProfile/' + email + '/' + password;

        return this.http.get(url)
            .map(resp => {
                let result = resp.json() as User;
                return result;
            });
        //.catch<Account>(this.handleError); //TODO :Handle error
    }

    setUserProfile(user: User) {
        if (user) {
            //this.user = user;
            localStorage.setItem('customerGuid', user.customerGuid);
            localStorage.setItem('customerName', user.customerName);
	    localStorage.setItem('isConsultant', user.isConsultant);
        }
    }

    getUserProfile() {

        const customerGuid = localStorage.getItem('customerGuid');
        const customerName = localStorage.getItem('customerName');
	const isConsultant = localStorage.getItem('isConsultant');
        
        if (customerGuid == '' && customerName == '') {
            this.router.navigate(['/account/login']);
        }

        let user = new User();
        user.customerGuid = customerGuid;
        user.customerName = customerName;
	user.isConsultant = isConsultant === 'true' ? true : false;
    
        // if(this.user != null){
        //     return this.user;
        // }
        // else{
        //     this.router.navigate(['/account/login']);
        // }
        return user;
    }
}
export class User {
    public customerGuid: string;
    public customerName: string;
    public isConsultant: boolean;
}