import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GMapModule } from 'primeng/primeng';
import { Marker, MarkerIcon, MultipuleMarker } from '../services/google-map-service';
import { Helpers } from '../../shared/helpers/helper';
//declare var document: any;
declare var google: any;

@Component({
  selector: 'app-map-new',
  templateUrl: 'map-new.component.html',
  styleUrls: ['map-new.component.scss']
})

export class MapNewComponent implements OnInit, OnChanges, AfterViewChecked {

  //private height = "400px"; //used in html file to display map
  public options: any;
  public overlays: any[];
  public dialogVisible: boolean;
  public paddockMarker: any;
  public siteMarker: number;
  public selectedPosition: any;
  public selectedPositionLat: number;
  public selectedPositionLng: number;
  public infoWindow: any;
  public siteMarkerError = null;
  public updatedMarkerPaddockName: string = null;
  public updatedMarkerPaddockId: string = null;
  public updatedMarkerSiteName: number;
  public updatedMarkerSiteId: number;
  public autoHighlightDropdown = false;
  public showMarkerInfo = false;
  public dialogEditModel = false;
  public selectedGoogleMarker: any;
  public selectedMarker: Marker;
  public focusSet = false;
  public listOfMarkers: any;
  public lastPaddockMarker: string;
  //private draggable: boolean;
  public dialogVisibleMultipule: boolean;
  public chkForPrevJobMultipuleEventAllow: boolean;
  public multipuleMarker: MultipuleMarker;

  @Input() markers: Marker[];
  @Input() allowMarkerAddAndUpdate: boolean;
  @Input() height: string = "1212px"; //used in html file to display map;
  @Input() width: string = "100%"; //used in html file to display map;
  @Input() allowMarkerDelete = false;
  //@Input() paddockList: any[];
  //@Input() fullPaddockList: string[];

  // TODO:  may not required the allowMarkerAddOriginal flag below
  public allowMarkerAddOriginal: boolean = false;
  public allowMarkerAddOriginalMultipule: boolean = false;
  paddockList: any[];
  //@Input() fullPaddockList: string[] = [];
  //@Input() fullPaddockList: string[] = ['Audi','BMW','Fiat','Ford','Honda','Jaguar','Mercedes','Renault','Volvo','VW'];
  // @Output() newPaddockSiteMarkerAdded: EventEmitter<Marker> = new EventEmitter();
  // @Output() paddockSiteMarkerUpdated: EventEmitter<Marker> = new EventEmitter();
  @Output() markerUpdated: EventEmitter<Marker> = new EventEmitter();
  @Output() markerDeleted: EventEmitter<Marker> = new EventEmitter();
  @Output() markerMultipuleUpdated: EventEmitter<MultipuleMarker> = new EventEmitter();

  @Input('showPreviousJobs') showPreviousJobs: boolean;
  //@Input('chkForPrevJobMultipuleEventAllow') chkForPrevJobMultipuleEventAllow: boolean;

  map: any;
  @ViewChild('paddockListControl', {static: false}) paddockListControl: any;

  constructor(public cdRef: ChangeDetectorRef) {
    this.listOfMarkers = [];
    this.chkForPrevJobMultipuleEventAllow = false;
    this.multipuleMarker = new MultipuleMarker();
    this.lastPaddockMarker = "";
  }

  setMap(event) { // only gets called first time
    this.map = event.map;
    this.updateMap(true);
  }

  ngOnInit() {
    this.options = {
      //mapTypeId: 'satellite'
      mapTypeId: google.maps.MapTypeId.HYBRID,
      gestureHandling: 'greedy',
      //mapTypeControlOptions: {
      //  style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      //  position: google.maps.ControlPosition.TOP_RIGHT
      //},
      mapTypeControl: false

      //scrollWheelZoom:'center'
    };
    this.infoWindow = new google.maps.InfoWindow();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (!!changes['allowMarkerAddAndUpdate']) {
      this.allowMarkerAddOriginal = this.allowMarkerAddAndUpdate;
    }

    if (!!changes['markers']) {
      this.markers = changes['markers'].currentValue;
    }

    if (this.map) {
      // this.allowMarkerDelete is only allowed for current job
      // hence for previous jobs, it is always false
      if (this.allowMarkerDelete) {
        this.updateMap(false);
      } else {
        if (!!this.selectedGoogleMarker) {
          this.updateMap(false);
          this.selectedGoogleMarker = null;
        } else {
          this.updateMap(true);
        }
      }
    } else {
      // do nothing as setMap will update the map
    }
  }

  ngAfterViewChecked() {
    if (this.paddockListControl && !this.focusSet) {
      this.paddockListControl.focusInput();
      this.focusSet = true;
      // TODO: use helper function as below
    }
  }

  handleMapClick(event) {
    this.dialogVisible = true;
    this.allowMarkerAddOriginal = this.allowMarkerAddAndUpdate;
    this.selectedPosition = event.latLng;
    this.selectedPositionLat = this.round(this.selectedPosition.lat(), 6);
    this.selectedPositionLng = this.round(this.selectedPosition.lng(), 6);
    if (!this.showPreviousJobs)
      this.paddockMarker = this.lastPaddockMarker;

  }


  showAddCurrentJobDialog() {
    if (this.listOfMarkers.length > 0) {
      this.dialogVisibleMultipule = true;
      this.allowMarkerAddOriginalMultipule = true;
    }
  }

  public CancelMultiDialog() {
    this.dialogVisibleMultipule = false;
    this.allowMarkerAddOriginalMultipule = false;
    let markers = this.markers;
    let listOfMarkers = this.listOfMarkers;

    if (markers.length > 0) {
      for (let i = 0; i < markers.length; i++) {
        for (let j = 0; j < listOfMarkers.length; j++) {
          if (listOfMarkers[j].PaddockId == markers[i].paddockId && listOfMarkers[j].SiteId == markers[i].siteId) {
            let split = markers[i].iconUrl.split('_g');
            this.markers[i].iconUrl = split[0] + ".png";
          }
        }
      }
      this.loadMapFromOverlays(false);
    }

    this.listOfMarkers = [];

  }

  handleOverlayClick(event) {
    //console.log('event: ', event.overlay.id);
    this.selectedGoogleMarker = event;
    this.selectedPosition = event.overlay.position;
    this.selectedPositionLat = this.round(this.selectedPosition.lat(), 6);
    this.selectedPositionLng = this.round(this.selectedPosition.lng(), 6);
    const lat = event.overlay.position.lat();
    const lng = event.overlay.position.lng();
    const markerId = event.overlay.id;
    this.selectedMarker = this.getMarkerFromId(markerId);

    //give the check box condition
    console.log('selectedMarker: ', this.selectedMarker);
    this.paddockMarker = this.selectedMarker.paddockName;
    this.siteMarker = this.selectedMarker.siteName;
    if (this.showPreviousJobs) {
      if (this.chkForPrevJobMultipuleEventAllow) {
        this.dialogEditModel = true;
        this.dialogVisible = true;
        this.allowMarkerAddOriginal = true;
      }
      else {
        if (this.markers.length > 0) {
          for (let i = 0; i < this.markers.length; i++) {
            if (this.selectedMarker.paddockId == this.markers[i].paddockId
              && this.selectedMarker.siteId == this.markers[i].siteId) {
              var iconUrl = this.markers[i].iconUrl;
              if (iconUrl !== undefined && iconUrl !== "") {
                let split = iconUrl.split(".png");
                if (!this.CheckMarksALreadyExists(this.selectedMarker.paddockId, this.selectedMarker.siteId)) {
                  let subString = (split[0][(split[0].length - 2)].trim().toLowerCase() + split[0][(split[0].length - 1)].trim().toLowerCase());
                  if (!(subString == "_g")) {
                    this.markers[i].iconUrl = split[0] + "_g" + ".png";
                    this.listOfMarkers.push({ lat: this.selectedPositionLat, lng: this.selectedPositionLng, markerId: markerId, paddockName: this.paddockMarker, siteMarker: this.siteMarker, PaddockId: this.selectedMarker.paddockId, SiteId: this.selectedMarker.siteId });
                  }
                }
                else {
                  let subString = (split[0][(split[0].length - 2)].trim().toLowerCase() + split[0][(split[0].length - 1)].trim().toLowerCase());
                  if (subString == "_g") {
                    let final = split[0].split("_g");
                    this.markers[i].iconUrl = final[0] + ".png";
                    let index = this.listOfMarkers.findIndex(q => q.PaddockId == this.selectedMarker.paddockId && q.SiteId == this.selectedMarker.siteId);
                    this.listOfMarkers.splice(index, 1);
                  }
                }
                this.loadMapFromOverlays(false);
              }
            }
          }
        }
      }
    }
    else {
      this.dialogEditModel = true;
      this.dialogVisible = true;
      this.allowMarkerAddOriginal = true;
    }
  }

  private CheckMarksALreadyExists(paddockId: string, siteId: string) {
    let obJMarker = this.listOfMarkers.find(x => x.PaddockId === paddockId && x.SiteId === siteId);
    if (obJMarker != null && obJMarker != undefined)
      return true;
    else
      return false;
  }

  public cancelDialog() {
    this.dialogVisible = false;
    this.paddockMarker = null;
    this.siteMarker = null;
    this.siteMarkerError = null;
    this.selectedPosition = null;
    this.selectedPositionLat = null;
    this.selectedPositionLng = null;
    this.selectedGoogleMarker = null;
    this.selectedMarker = null;
    this.focusSet = false;
  }

  public deleteMarker(): void {
    var r = confirm("Are you sure?");
    if (r == true) {
      if (this.selectedGoogleMarker) {
        this.selectedGoogleMarker.overlay.setMap(null);
        this.markerDeleted.emit(this.selectedMarker);
      } else {
        alert('Marker could not be deleted. Please try again later.');
      }
      this.cancelDialog();
    }
  }


  addMarker() {
    this.lastPaddockMarker = this.paddockMarker;
    const title = this.paddockMarker + '-' + this.siteMarker;
    const lat = this.selectedPositionLat;
    const lng = this.selectedPositionLng;
    if (!this.markers || this.markers.length === 0) {
      this.markers = [];
    }

    let paddockId = null;
    for (let i = 0; i < this.markers.length; i++) {
      let paddock = this.markers[i].paddockName;
      if (paddock.toLowerCase() === this.paddockMarker.toLowerCase()) {
        paddockId = this.markers[i].paddockId
        console.log('paddockId: ', paddockId);
      }
    }

    const marker = {
      lat: lat,
      lng: lng,
      paddockName: this.paddockMarker,
      paddockId: paddockId,
      siteId: this.selectedMarker ? this.selectedMarker.siteId : null,
      siteName: this.siteMarker,
      draggable: true
    } as Marker;

    if (!marker.siteId) { //new marker 
      this.markers.push(marker);
      this.overlays = this.getOverlays();
      this.overlays.push(new google.maps.Marker({ position: { lat: lat, lng: lng }, title: title, draggable: true }));
      this.loadMapFromOverlays(false);
    }

    this.updateMap(true);
    this.markerUpdated.emit(marker);
    this.paddockMarker = null;
    this.siteMarker = null;
    this.dialogVisible = false;
    this.dialogEditModel = false;
    this.focusSet = true;
  }

  addMultipulMarkers() {
    let markerList = [];
    if (this.listOfMarkers != null && this.listOfMarkers != undefined) {
      for (let i = 0; i < this.listOfMarkers.length; i++) {
        const marker = {
          lat: this.listOfMarkers[i].lat,
          lng: this.listOfMarkers[i].lng,
          paddockName: this.listOfMarkers[i].paddockName,
          paddockId: this.listOfMarkers[i].PaddockId,
          siteId: this.listOfMarkers[i].SiteId,
          siteName: this.listOfMarkers[i].siteMarker,
          draggable: false
        } as Marker;
        markerList.push(marker);
      }
      this.multipuleMarker.MarkerList = markerList;
      this.markerMultipuleUpdated.emit(this.multipuleMarker);
      this.paddockMarker = null;
      this.siteMarker = null;
      this.dialogVisibleMultipule = false;
      this.allowMarkerAddOriginalMultipule = false;
      this.listOfMarkers = [];
    }
  }

  handleDragEnd(event) {
    //console.log('Marker Dragged event id: ', event.overlay.id);
    const lat = event.overlay.position.lat();
    const lng = event.overlay.position.lng();
    const markerId = event.overlay.id;
    let updatedMarker = this.getMarkerFromId(markerId);

    if (updatedMarker) {
      updatedMarker.paddockName = this.paddockMarker ? this.paddockMarker : updatedMarker.paddockName;
      updatedMarker.siteName = this.siteMarker ? this.siteMarker : updatedMarker.siteName;
      updatedMarker.lat = lat;
      updatedMarker.lng = lng;
    }
    this.markerUpdated.emit(updatedMarker);
  }

  private getMarkerFromId(markerId: string): Marker {
    const paddockIdAndSiteId: string[] = markerId.split("*", 2);
    const marker = this.markers.find(x => x.paddockId === paddockIdAndSiteId[0] && x.siteId === paddockIdAndSiteId[1]);
    return marker;
  }

  updateMap(shouldFitBound: boolean) {
    if (this.markers && this.markers.length > 0) {
      this.loadMapFromOverlays(shouldFitBound);
    } else {
      this.setDefaultMap();
    }
  }

  loadMapFromOverlays(shouldFitBound: boolean) {
    this.overlays = this.getOverlays();
    let bounds;
    if (this.overlays && this.overlays.length > 0) {
      bounds = new google.maps.LatLngBounds();
      this.overlays.forEach(marker => {
        bounds.extend(marker.getPosition());
      });
      if (shouldFitBound) {
        this.fitBound(bounds);
      }
    }
  }

  setDefaultMap() {
    let bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(-31.953512, 115.857048),
      new google.maps.LatLng(-31.753512, 115.857048)
    );
    this.fitBound(bounds);
  }

  fitBound(bounds: any) {
    if (this.map) {
      setTimeout(() => { // map will need some time to load
        this.map.fitBounds(bounds); // Map object used directly
      }, 0); // TODO: set timer if required
    }
  }

  getOverlays() {
    let gMapMarkers = [];
    this.markers.forEach(x => {
      gMapMarkers.push(new google.maps.Marker({
        position: { lat: x.lat, lng: x.lng },
        label: {
          color: 'white',
          text: x.paddockName + '-' + x.siteName
        },
        icon: {
          labelOrigin: new google.maps.Point(16, 45),
          //url: 'assets/google_map_marker.png',
          url: x.iconUrl ? x.iconUrl : MarkerIcon.default,
          scaledSize: new google.maps.Size(45, 45),
          origin: new google.maps.Point(0, 0),
          //anchor: new google.maps.Point(32, 65),
        },
        id: x.paddockId + '*' + x.siteId,
        draggable: this.allowMarkerAddAndUpdate,
      }));
    });
    return gMapMarkers;
  }

  zoomIn(map) {
    map.setZoom(map.getZoom() + 1);
  }

  zoomOut(map) {
    map.setZoom(map.getZoom() - 1);
  }

  clear() {
    this.overlays = [];
  }

  filterPaddockList(event) {
    setTimeout(() => {
      this.paddockList = [];
      if (this.markers) {
        for (let i = 0; i < this.markers.length; i++) {
          let paddock = this.markers[i].paddockName;
          if (paddock.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
            const paddockExists = this.paddockList.find(x => x.toLowerCase() === paddock.toLowerCase());
            if (!paddockExists) {
              this.paddockList.push(paddock);
            }
          }
        }
      }
      this.paddockList = this.paddockList.sort(Intl.Collator().compare);
    }, 100);
  }

  onDropdownSelect(event) {
    this.validateSiteMarker(null);
    //console.log('onDropdownSelect: event: ', event);
  }


  validTextForMarker(event: any) {
    if (event !== null) {
      const pattern = /[0-9\+\-\ ]/;

      let inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode != 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }

  validateSiteMarker(event: any) {
    let entryExists = false;
    this.markers.forEach(x => {
      if (x.paddockName.toLowerCase() == this.paddockMarker.toLowerCase() && x.siteName == this.siteMarker) {
        console.log('this.paddockMarker.toLowerCase(): ', this.paddockMarker.toLowerCase());
        console.log('x.paddockName.toLowerCase(): ', x.paddockName.toLowerCase());
        entryExists = true;
      }
    });
    if (entryExists) {
      this.siteMarkerError = "Site exists for selected Paddock";
    } else {
      this.siteMarkerError = null;
    }
  }

  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  public getAddButtonLabel(): string {
    return this.editMode ? 'Add' : 'Add to current Job';
  }

  get editMode() {
    return !this.selectedMarker || (this.allowMarkerDelete && this.dialogEditModel);
  }
}
