import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormComponentBase } from '../base-classes/form-component-base';

@Component({
  selector: 'app-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextboxComponent),
      multi: true,
    },
  ]
})
export class TextboxComponent extends FormComponentBase {

  @Input() field: any;

  @Input() cols: string = 'col-md-3';
  @Input() name: string;
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() autofocus: boolean = false;
  @Input() type: any = 'text';
  @Input() readonly: string = null;

  @Input() maxlength: any;
  @Input() placeholder: string = '';
  @Input() pattern: string;

  @Input() min: any = 0;
  @Input() max: any;

  get theLabel(): string {
    return this.label ? this.label : this.name;
  }
}
