import { Component, OnInit } from '@angular/core';
import { AlertService, AlertMessage } from '../../shared/service/alert.service';
import { AlertType } from '../enum/alert-type';
import { Message } from 'primeng/components/common/api';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit {
    objAlert: AlertMessage;
    alertClass: string;
    autoHideDelay: number = 10000;
    messages: Message[] = [];

    constructor(
        private alertService: AlertService) {
    }

    ngOnInit() {
        this.messages = [];
        this.alertService.alertStatus.subscribe((msg: AlertMessage) => {
            if (msg && msg.message) {
                const message = this.getMessage(msg);
                this.messages.push({ severity: message.severity, summary: message.summary, detail: message.detail });
                if (msg.autoHide) {
                    this.autHideAlert();
                }
            }
        });
    }

    autHideAlert() {
        setTimeout(() => {
            this.onCloseAlert();
        }, this.autoHideDelay);
    }

    onCloseAlert() {
        let objCloseAlert: AlertMessage = { type: null, message: null, autoHide: true };
        this.alertService.showAlert(null, null, false);
        this.messages = [];
    }

    getMessage(msg: AlertMessage): Message {
        let message = {
            detail: msg.message
        } as Message;
        
        switch (msg.type) {
            case AlertType.Success:
                message.summary = 'Awesome!';
                message.severity = 'success';
                break;
            case AlertType.Info:
                message.summary = 'Information!';
                message.severity = 'info';
                break;
            case AlertType.Warning:
                message.summary = 'Warning!';
                message.severity = 'warn';
                break;
            case AlertType.Danger:
                message.summary = 'Oops!';
                message.severity = 'error';
                break;
            default:
                break;
        }
            console.log('message: ', message);
            return message;
    }
}