import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import * as $ from 'jquery';

const API_KEY = 'AIzaSyC2NWN7ENK-9yU7J5bwHtmzpDTcq0GRoMs';
const url = 'https://maps.googleapis.com/maps/api/js?key=' + API_KEY + '&callback=initMap';
declare var window: any;
declare var document: any;
declare var google: any;


@Injectable()
export class GoolgleMapService {
    private loadMap: Promise<any>;

    constructor(private http: Http) {
        this.loadMap = new Promise((resolve) => {
            window['initMap'] = () => {
                resolve();
            };
            this.loadScript()
        });
    }

    public initSampleMap(): Promise<any> {
        return this.loadMap;
    }
    public initMap(historyMap): Promise<any> {
        return this.loadMap;
    }

    private loadScript() {
        let script = document.createElement('script');
        script.src = url;
        script.type = 'text/javascript';

        if (document.body.contains(script)) {
            return;
        }
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    EnableLabelMarkers() {
        // start add label to marker http://stackoverflow.com/a/22272973
        var markerSize = { x: 8, y: 40 };

        google.maps.Marker.prototype.setLabel = function (label) {
            this.label = new MarkerLabel({
                map: this.map,
                marker: this,
                text: label
            });
            this.label.bindTo('position', this, 'position');
        };

        var MarkerLabel = function (options) {
            this.setValues(options);
            this.span = document.createElement('span');
            this.span.className = 'map-marker-label';
        };

        MarkerLabel.prototype = $.extend(new google.maps.OverlayView(), {
            onAdd: function () {
                this.getPanes().overlayImage.appendChild(this.span);
                var self = this;
                this.listeners = [
                    google.maps.event.addListener(this, 'position_changed', function () { self.draw(); })];
            },
            draw: function () {
                var text = String(this.get('text'));
                var position = this.getProjection().fromLatLngToDivPixel(this.get('position'));
                this.span.innerHTML = text;
                this.span.style.left = position.x + markerSize.x + 'px';
                this.span.style.top = (position.y - markerSize.y + 32) + 'px';
            }
        });
    }

}

export class MultipuleMarker {
  MarkerList: Array<Marker>;
}
export class Marker {
    lat: number;
    lng: number;
    //label: string;
    draggable: boolean = false;
    paddockId: string
    paddockName: string; ;
    siteName: number;
    siteId: string
    iconUrl: string;
}

export const MarkerIcon = {
    default: 'assets/google_map_marker_red.png',
    blue: 'assets/google_map_marker_blue.png',
    blueGray: 'assets/google_map_marker_blue_g.png',
    yellow: 'assets/google_map_marker_yellow.png',
    yellowGray: 'assets/google_map_marker_yellow_g.png',
    green: 'assets/google_map_marker_green.png',
    greenGray: 'assets/google_map_marker_green_g.png',
    purple: 'assets/google_map_marker_purple.png',
    purpleGray: 'assets/google_map_marker_purple_g.png',
    black: 'assets/google_map_marker_black.png',
    blackGray: 'assets/google_map_marker_black_g.png'
};