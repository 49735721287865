import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'select-box',
	templateUrl: 'select-box.component.html'
})

export class SelectBoxComponent implements OnInit {

	constructor() { }

	ngOnInit() { }
}