import { Component, OnInit, Input, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent implements OnInit, AfterContentInit {

  @Input() inputField: any;

  @Input() columnClass: string = 'col-md-3';

  @Input() labelClass: string = '';

  @Input() iconClass: string = '';

  @Input() label: string;

  @Input() required: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  ngAfterContentInit() {
  }

}
